<template>
  <div class="CommanderDamage">
    <Tabs>
      <template v-slot:tab1--title>
        <i class="far fa-helmet-battle"></i>Your Damage
      </template>
      
      <template v-slot:tab1>
        <div class="commanderDamageCounters" v-for="(commander, commanderNumber) in $store.state.player.commanderList" :key="commander.id">

          <div class="commanderTitle">
            <h2>Damage Dealt By</h2>
            <h3>{{commander.name}}</h3>
          </div>
          <CommanderCounter
            v-for="opponent in opponentArray"
            :key="opponent.publicId"
            :commanderNumber="commanderNumber"
            :opponent="opponent"
            :canChange="true"
            :game="$store.state.game.game_obj">
          </CommanderCounter>
        </div>
      </template>
      <template v-slot:tab2--title>
        <i class="far fa-helmet-battle"></i>All Damage
      </template>
      <template v-slot:tab2>
        <h2>All Commander Damage</h2>
        <CommanderDamageTable></CommanderDamageTable>
      </template>
    </Tabs>
  </div>
</template>

<script>
  import CommanderCounter from "@/components/CommanderCounter.vue";
  import CommanderDamageTable from "@/views/game/CommanderDamageTable";
  import Tabs from "@/components/Tabs.vue";
  export default {
    name: 'CommanderDamage',
    components: {
      CommanderCounter,
      CommanderDamageTable,
      Tabs,
    },
    data: () => {
      return {

      }
    },
    computed: {
      opponentArray: function() {
        let ret = Object.assign({}, this.$store.state.game.game_obj.players);
        delete ret[this.$store.state.player.publicId];
        return ret;
      }
    },
    methods: {
      getCounterValue() {

      }
    }
  }
</script>

<style scoped lang="less">
.CommanderDamage {
  width: 100%;

  .commanderDamageCounters {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
  }

  .damageTable {
    display: flex;

    justify-content: center;
  }

  .commanderTitle {
    h2 {
      margin-bottom: 0;
    }
    h3 {
      font-style: italic;
      margin-top: 0;
      font-size: 1em;
    }
  }

}
</style>