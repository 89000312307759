<template>
  <Tile z="1" class="CommanderCounter">
    <div class="content">
      <div class="playerAvatar">
        <div class="damageChart" :style="getChart(opponent, commanderNumber)">
          <ProfilePic :icon="opponent.avatar"></ProfilePic>
        </div>
        <span class="value font-style--readable">{{$store.state.player.commanderDamage[opponent.publicId+"_"+`${commanderNumber}`].value}}</span>
      </div>
      <div class="rightSide">
        <div class="name font-size--large">{{opponent.name}}</div>
        <div class="buttons">
          <button class="counter counter--up" @click="changeCommanderDamage(opponent.publicId, commanderNumber, 1)"><font-awesome-icon :icon="['fas', 'triangle']"/></button>
          <button class="counter counter--down" @click="changeCommanderDamage(opponent.publicId, commanderNumber, -1)"><font-awesome-icon :icon="['fas', 'triangle']"/></button>
        </div>
      </div>
    </div>
  </Tile>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import ProfilePic from "@/components/ProfilePic.vue";
  import Tile from "@/components/Tile.vue";
  export default {
    name: 'CommanderCounter',
    components: {
      ProfilePic,
      Tile
    },
    props: ['opponent', 'game', 'canChange', 'commanderNumber'],
    data: () => {
      return {
        count: 0
      }
    },
    computed: {

    },
    methods: {
      async changeCommanderDamage(playerId, commanderNumber, delta) {
        
        this.count = this.count + delta;
        this.$store.commit('change_commander_damage', {
          counterName: playerId+"_"+commanderNumber,
          counterDelta: delta,
        });
      }, 
      getChart(opponent, commanderNumber) {
        return `background: conic-gradient(var(--app-color--primary__hex) 0% ${Math.floor(this.$store.state.player.commanderDamage[opponent.publicId+"_"+`${commanderNumber}`].value/21 * 100)}%, black 0% 100%);`
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.CommanderCounter {
  color: white;
  font-size: 26px;

  .content {
    display: flex;
    flex-flow: row;

    .playerAvatar {
      display: flex;
      flex-flow: column;

      .damageChart {
        padding: 5px;
        border-radius: 50%;
        height: 50px;
        width: 50px;

        .playerAvatar {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .value {
        margin-top: 5px;
        font-size: .5em;
        font-weight: bold;
      }
    }

    .rightSide {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      flex-grow: 1;
      margin-left: 10px;

      .name {
        display: flex;
        width: 100%;
        max-width: 180px;
        overflow-x: hidden;
        flex-grow: 1;
        margin-bottom: 20px;
        margin-right: 10px;
        align-items: center;
        white-space: nowrap;
      }
    }

    .buttons {
      display: flex;
      flex-flow: column;

      .counter {
        width: 50px;
        height: 50%;
        border: none;
        background: @app-color-primary;
        color: white;
        outline: none;
        cursor: pointer;
        .corners--rounded();

        &--down {
          margin-top: 3px;

          svg {
            transform: rotate(180deg);
          }
        }

        &:active {
          box-shadow: inset 0px 0px 3px 1px #000000;
          opacity: .8;
        }
      }
    }
  }
}

</style>
